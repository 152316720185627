import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Subject, takeUntil, tap, throwError } from 'rxjs';
import { AlertService, AlertType, LoginService } from '../shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  requestId: number;
  isDennyActive: boolean;
  isUserLogIn: boolean;
  isApproveActive: boolean;
  private destroy$ = new Subject<void>();

  form: UntypedFormGroup;
  showPassword = false;
  showPasswordLogin = false;

  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public loginService: LoginService,
    public translate: TranslateService,
  ) {
    this.isUserLogIn = this.loginService.isUserLogIn();
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.requestId = params.id;
      this.isDennyActive = params.id > 0 && params.decline === 'true';
      this.isApproveActive = params.id > 0 && params.approve === 'true';
    });
  }

  ngOnInit(): void {
    this.loginService.subscribeAuthState();
  }

  signInWithGoogle(): void {
    this.loginService.logIn();
  }

  refreshToken(): void {
    this.loginService.refreshAuthToken();
  }

  onPasswordLogin() {
    this.showPasswordLogin = true;

    this.form = this.formBuilder.group({
      email: [''],
      password: [''],
    });
  }

  submitPasswordLogin() {
    this.loginService
      .logInWithPassword(this.form.value)
      .pipe(
        tap((response) => this.loginService.handleSuccessfulLogin(response)),
        catchError((error) => {
          this.alertService.showAlert(error.error, AlertType.error);
          return throwError(error);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.loginService.unSubscribeAuthState();
  }
}
