import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Moment } from 'moment';
import { Observable, shareReplay } from 'rxjs';
import { EmployeeCertificate, CertificateItem } from '../models/employee-certificates.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeCertificatesService {
  private urlBase: string = 'api/employee-certificates';
  private certificates$: Observable<CertificateItem[]>;

  constructor(private httpClient: HttpClient) {}

  getAllCertificates(): Observable<CertificateItem[]> {
    this.certificates$ = this.certificates$ ?? this.fetchAllCertificates().pipe(shareReplay());
    return this.certificates$;
  }

  getEmployeeCertificates(employeeId: number) {
    return this.httpClient.get<EmployeeCertificate[]>(`${this.urlBase}/${employeeId}`);
  }

  createEmployeeCertificate({ employeeId, ...payload }: CreateEmployeeCertificateDTO) {
    return this.httpClient.post<EmployeeCertificate[]>(`${this.urlBase}/${employeeId}`, payload);
  }

  deleteEmployeeCertificate(employeeId: number, certificateId: number) {
    return this.httpClient.delete<void>(
      `${this.urlBase}/${employeeId}?certificateId=${certificateId}`,
    );
  }

  private fetchAllCertificates(): Observable<CertificateItem[]> {
    return this.httpClient.get<CertificateItem[]>('api/certificate');
  }
}

export type CreateEmployeeCertificateDTO = {
  employeeId: number;
  certificateId: number;
  validFrom: Moment;
  validTo: Moment;
};
