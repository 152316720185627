import { effect, Injectable, Injector, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  EmployeePartial,
  EmployeeTeam,
  IAllCertificates,
  IAllSkills,
  IEmployee,
  IEmployeeNameEmail,
  IEmployeeSearchRequest,
  IEmployeeSearchResponse,
  IEmployeeSimple,
  IPosition,
} from '../models/';
import { catchError, lastValueFrom, map, Observable, tap, throwError } from 'rxjs';
import { IEmployeeDreamixBirthdayModel } from '../models/employee-dreamix-birthday.model';
import { IPagination } from '../models/pagination.model';
import { IReferral } from '../models/referral.model';
import { EmployeeProject } from '../models/project.model';
import { ProjectHistory, ProjectHistoryRequest } from '../models/project-history.model';
import { IManagerSubordinatesModel } from '../models/manager-subordinates.model';
import { AlertService, AlertType } from './alert.service';

const filtersMappingGuide = [
  {
    propertiesToMap: ['ids'],
    term: 'certificates',
  },
  {
    propertiesToMap: ['ids'],
    term: 'position',
  },
  {
    propertiesToMap: ['optionalIds', 'requiredIds'],
    term: 'skills',
  },
];

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private urlBase: string = 'api/employees';

  // signals
  private _employees = signal<IPagination<IEmployeeSearchResponse>>(undefined);
  readonly employees = this._employees.asReadonly();
  employeeDaysLeft = signal<any>(undefined);
  // readonly employeeDaysLeft = this._employeeDaysLeft.asReadonly();

  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private injector: Injector,
  ) {}

  public uploadImg(id: number, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<string>(`${this.urlBase}/${id}/photo`, formData);
  }

  fetchDaysLeftReport(fromDate: string): Observable<{ buffer: Blob; fileName: string }> {
    const url = `${this.urlBase}/vacation/export/${fromDate}`;

    return this.httpClient
      .get<Blob>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/octet-stream',
        }),
        observe: 'response',
        responseType: 'arraybuffer' as 'json',
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const header = response.headers.get('Content-Disposition');

          return {
            buffer: response.body,
            fileName: header.split(';')[1].split('=')[1].replaceAll('"', ''),
          };
        }),
      );
  }

  fetchEmployees({ params, payload }: { params: HttpParams; payload?: IEmployeeSearchRequest }) {
    const signal = toSignal(
      this.httpClient.post<IPagination<IEmployeeSearchResponse>>(
        `${this.urlBase}/search`,
        payload,
        {
          params,
        },
      ),
      { injector: this.injector },
    );
    effect(
      () => {
        if (!signal()) return this._employees.set(undefined);

        // error handling
        try {
          signal();
        } catch (e) {
          this.alertService.showAlert(e, AlertType.error);
        }
        this._employees.set(signal());
      },
      { injector: this.injector, allowSignalWrites: true },
    );
  }

  fetchEmployeeDaysLeft({ employeeId, fromDate }: { employeeId: number; fromDate: string }) {
    const url = `${this.urlBase}/vacation/${employeeId}`;
    const params = new HttpParams().set('fromDate', fromDate);

    this.httpClient
      .get<any>(url, { params })
      .pipe(
        tap((response) => this.employeeDaysLeft.set(response)),
        catchError((error) => {
          this.alertService.showAlert(error, AlertType.error);
          return throwError(error);
        }),
      )
      .subscribe();
  }

  getAllEmployees(): Observable<IEmployee[]> {
    return this.httpClient.get<IEmployee[]>(this.urlBase);
  }

  getAllEmployeesSimple(): Observable<IEmployeeSimple[]> {
    return this.httpClient.get<IEmployeeSimple[]>(`${this.urlBase}/simple`);
  }

  getAllEmployeesNameEmail(): Observable<IEmployeeNameEmail[]> {
    return this.httpClient.get<IEmployeeNameEmail[]>(`${this.urlBase}/name-email`);
  }

  getAllEmployeesTeam(): Observable<EmployeeTeam[]> {
    return this.httpClient.get<EmployeeTeam[]>(`${this.urlBase}/team`);
  }

  getActiveEmployeesByProjectId(projectId: number): Observable<IEmployee[]> {
    return this.httpClient.get<IEmployee[]>(`${this.urlBase}/by-project/${projectId}`);
  }

  async loadMyProjects(id: number) {
    return await lastValueFrom(
      this.httpClient.get<Array<EmployeeProject>>(`${this.urlBase}/${id}/projects`),
    );
  }

  getProjectHistoryByEmployeeId(employeeId: number): Observable<ProjectHistory[]> {
    return this.httpClient.get<ProjectHistory[]>(`${this.urlBase}/${employeeId}/projects-history`);
  }

  saveEmployeeProjectHistory(
    employeeId: number,
    projectHistory: ProjectHistoryRequest[],
  ): Observable<ProjectHistoryRequest[]> {
    return this.httpClient.post<ProjectHistoryRequest[]>(
      `${this.urlBase}/${employeeId}/projects-history`,
      projectHistory,
    );
  }

  getAllClientEmployees(projectId: number): Observable<IEmployee[]> {
    return this.httpClient.get<IEmployee[]>(`${this.urlBase}/client/${projectId}`);
  }

  getPartialEmployees(): Observable<EmployeePartial[]> {
    return this.httpClient.get<EmployeePartial[]>(`${this.urlBase}/partial`);
  }

  addEmployee(employee: Partial<IEmployee>): Observable<IEmployee> {
    return this.httpClient.post<IEmployee>(`${this.urlBase}/create`, employee);
  }

  editEmployee(employee: IEmployee): Observable<IEmployee> {
    return this.httpClient.put<IEmployee>(`${this.urlBase}`, employee);
  }

  getAllEmployeesBirthdays(): Observable<IEmployeeDreamixBirthdayModel[]> {
    return this.httpClient.get<IEmployeeDreamixBirthdayModel[]>(`${this.urlBase}/dreamixBd`);
  }

  getEmployeeById(id: number): Observable<IEmployee> {
    return this.httpClient.get<IEmployee>(`${this.urlBase}/${id}`);
  }

  deleteEmployeeById(id: number): Observable<IEmployee> {
    return this.httpClient.delete<IEmployee>(`${this.urlBase}/${id}`);
  }

  getAllReferrals(params?: HttpParams): Observable<IPagination<IReferral>> {
    return this.httpClient.get<IPagination<IReferral>>(`${this.urlBase}/referrals`, { params });
  }

  getUtilizationRatesExport(
    fromDate: string,
    toDate: string,
    employeeId: string | null,
  ): Observable<any> {
    let params = new HttpParams().set('fromDate', fromDate).set('toDate', toDate);
    if (employeeId != null) {
      params = params.set('employeeId', employeeId);
    }
    return this.httpClient.get(`${this.urlBase}/export`, { params, responseType: 'arraybuffer' });
  }

  getEmployeePicture(userId: number): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/${userId}/photo/${Date.now()}.jpg`, {
      responseType: 'blob',
    });
  }

  getAllManagerSubordinates(managerId: number): Observable<IManagerSubordinatesModel[]> {
    return this.httpClient.get<IManagerSubordinatesModel[]>(
      `${this.urlBase}/getManagerSubordinates/${managerId}`,
    );
  }

  getHeadcount(fromDate: string): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/headcount?fromDate=${fromDate}`, {
      responseType: 'arraybuffer',
    });
  }

  mapFormValueToRequest(formValue: IEmployeeSearchRequest): IEmployeeSearchRequest {
    if (formValue) {
      const mappedValue = {} as IEmployeeSearchRequest;

      filtersMappingGuide.forEach(({ term, propertiesToMap }) => {
        if (formValue[term]) {
          mappedValue[term] = { ...formValue[term] };

          propertiesToMap?.forEach((propertyToMap) => {
            // map the array of objects to an array of ids
            mappedValue[term][propertyToMap] = mappedValue[term][propertyToMap]?.map(
              (item: IAllSkills | IAllCertificates | IPosition) => item.id,
            );
          });

          // check if all arrays are empty so we can remove term
          const isEmpty = propertiesToMap
            .map((propertyToMap) => !(mappedValue[term][propertyToMap]?.length > 0))
            .every(Boolean);

          if (isEmpty) delete mappedValue[term];
        }
      });

      // search
      if (formValue.search) {
        mappedValue.search = formValue.search;
      }

      return Object.keys(mappedValue).length > 0 ? mappedValue : null;
    }

    return null;
  }
}
